@font-face {
    font-family: 'Dax Condensed Bold';
    src: url('fonts/DaxCondensed-Bold.woff2') format('woff2'),
        url('fonts/DaxCondensed-Bold.woff') format('woff'),
        url('fonts/DaxCondensed-Bold.ttf') format('truetype'),
        url('fonts/DaxCondensed-Bold.svg#DaxCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dax Condensed Regular';
    src: url('fonts/DaxCondensed-Regular.woff2') format('woff2'),
        url('fonts/DaxCondensed-Regular.woff') format('woff'),
        url('fonts/DaxCondensed-Regular.ttf') format('truetype'),
        url('fonts/DaxCondensed-Regular.svg#DaxCondensed-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dax Condensed Medium';
    src: url('fonts/DaxCondensed-Medium.woff2') format('woff2'),
        url('fonts/DaxCondensed-Medium.woff') format('woff'),
        url('fonts/DaxCondensed-Medium.ttf') format('truetype'),
        url('fonts/DaxCondensed-Medium.svg#DaxCondensed-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

